import React from "react"

import ParsleyImg from "../assets/parsley-sim.png"
import Head from "../components/head"
import Layout from "../components/layout"
import * as parsleyStyles from "./parsley.module.scss"

const ParsleyContactPage = () => {
  return (
    <Layout>
      <Head title="Contact" />
      <h1>Parsley: Recipe Manager</h1>
      <img
        src={ParsleyImg}
        alt="Parsley: Recipe Manager"
        className={parsleyStyles.img}
      />
      <p>
        Thank you for downloading{" "}
        <a href="https://apps.apple.com/us/app/parsley-recipe-manager/id1607697734">
          Parsley: Recipe Manager
        </a>
      </p>
      <p>
        If you have any questions, comments, feedback, or anything else, we'd
        love to hear from you!
      </p>
      <p>
        <a href="mailto:kntreadway@gmail.com">Contact us</a>
      </p>
    </Layout>
  )
}

export default ParsleyContactPage
